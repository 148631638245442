jQuery(document).ready(function(){

    jQuery('.slider_guarantis').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            //autoplay: true,
            //speed: 50,
            //autoplaySpeed: 4000,
            //focusOnSelect: true,
            //centerMode: true,
            //adaptiveHeight: true,
            dots: true,
            //prevArrow: false,
            //nextArrow: false,
            responsive: [
                {
                    breakpoint: 993,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        //adaptiveHeight: true
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        //prevArrow: true,
                        //nextArrow: true,
                        adaptiveHeight: true
                    }
                }

            ]
        });

      jQuery('.responsive-testimonials').slick({
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          //autoplay: true,
          //speed: 50,
          //autoplaySpeed: 4000,
          //focusOnSelect: true,
         // centerMode: true,
          adaptiveHeight: true,
          dots: true,
          prevArrow: false,
          nextArrow: false,
          responsive: [
              {
                  breakpoint: 992,
                  settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      //adaptiveHeight: true
                  }
              },
              {
                  breakpoint: 768,
                  settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      //prevArrow: true,
                    //  nextArrow: true,
                      adaptiveHeight: true
                  }
              }

          ]
      });

    jQuery('#testimonials-type-slider').slick({
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          //autoplay: true,
          //speed: 50,
          //autoplaySpeed: 4000,
          //focusOnSelect: true,
         // centerMode: true,
         //adaptiveHeight: true,
          dots: true,
          prevArrow: false,
          nextArrow: false,
          responsive: [
              {
                  breakpoint: 992,
                  settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      //adaptiveHeight: true
                  }
              },
              {
                  breakpoint: 768,
                  settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      //prevArrow: true,
                    //  nextArrow: true,
                      adaptiveHeight: true
                  }
              }

          ]
      });


});