
jQuery(document).ready(function(){
        var delay_popup = 200;
        setTimeout(function () {
            var el = document.getElementById('fixed-banner-wrap');
            if (el){
                el.style.display = 'block';
            jQuery('body').addClass('bottom-up');
            }
        }, delay_popup);
    //
    jQuery("#fixed-banner-wrap .fb-close").click(function () {
        var expDate = new Date();
        if(jQuery("#fixed-banner-wrap").hasClass("first-banner")){
            expDate.setTime(expDate.getTime() + (15 * 30 * 60 * 1000));
            jQuery.cookie("popup", "24house", {expires: expDate});
        } else  if(jQuery("#fixed-banner-wrap").hasClass("holiday-banner")){
            expDate.setTime(expDate.getTime() + (15 * 60 * 1000));
            jQuery.cookie("popup-holiday", "24house", {expires: expDate});
        }
        jQuery(".fixed-banner-wrap").addClass('hide-banner');
        jQuery(".fixed-banner-wrap").removeClass('show-banner');
        jQuery('body').removeClass('bottom-up');

        setTimeout(function () {
            jQuery(".fixed-banner-wrap").addClass('hide-banner');
            jQuery(".fixed-banner-wrap").removeClass('show-banner');
            jQuery("#lhc_status_container, .ae-callback").removeClass('bottom-margin');
        }, 1000)
    });
    jQuery("#fixed-banner-wrap .order-link-btn").click(function () {
        var expDate = new Date();
        if(jQuery("#fixed-banner-wrap").hasClass("first-banner")){
            expDate.setTime(expDate.getTime() + (15 * 30 * 60 * 1000));
            jQuery.cookie("popup", "24house", {expires: expDate});
        } else  if(jQuery("#fixed-banner-wrap").hasClass("holiday-banner")){
            expDate.setTime(expDate.getTime() + (15 * 60 * 1000));
            jQuery.cookie("popup-holiday", "24house", {expires: expDate});
        }
    });

    if (((jQuery.cookie("popup") == null) && jQuery("#fixed-banner-wrap").hasClass("first-banner")) || ((jQuery.cookie("popup-holiday") == null ) && jQuery("#fixed-banner-wrap").hasClass("holiday-banner"))){
        setTimeout(function () {
            jQuery(".fixed-banner-wrap").addClass('show-banner');
            jQuery(".fixed-banner-wrap").removeClass('hide-banner');
            jQuery("#lhc_status_container, .ae-callback").addClass('bottom-margin');
        }, 4000)
    }
    else {
        jQuery(".fixed-banner-wrap").addClass('hide-banner');
        jQuery(".fixed-banner-wrap").removeClass('show-banner');
        jQuery("#lhc_status_container, .ae-callback").removeClass('bottom-margin');
    }
});
function scrollterms(id) {
    var yOffset = -170;
    var element = document.getElementById(id);
    var y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
}
/* chat button click */
/*
document.addEventListener('DOMContentLoaded', function() {
    $('body').click(function(e) {
    	if(window.outerWidth <= 600) {
    		if(e.target.id && e.target.id.toLowerCase() === 'lhc_status_container') {
                $('#online-icon').trigger('click')
            }
    	}
    });
});
*/
/* chat button click */