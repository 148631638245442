jQuery(document).ready(function() {
    $=jQuery;
    //jQuery('.material-select').material_select();
    // jQuery('.parallax').parallax();
    jQuery('.carousel.carousel-slider').carousel({fullWidth: true});
    jQuery('.tooltipped').tooltip({delay: 50});
    jQuery('.button-collapse').sideNav({
        menuWidth: '320px', // Default is 300
        edge: 'left', // Choose the horizontal origin
        closeOnClick: true, // Closes side-nav on <a> clicks, useful for Angular/Meteor
        draggable: true // Choose whether you can drag to open on touch screens
    });
	jQuery('.dropdown-button').dropdown({
      inDuration: 300,
      outDuration: 225,
      constrainWidth: false, // Does not change width of dropdown to that of the activator
      hover: false, // Activate on hover
      gutter: 0, // Spacing from edge
      belowOrigin: true, // Displays dropdown below the button
      alignment: 'left', // Displays dropdown with edge aligned to the left of button
      stopPropagation: true // Stops event propagation
    });
	jQuery(document).ready(function(){
		jQuery('.collapsible').collapsible();
	});
	//jQuery('ul.tabs').tabs();
	// my functions
	if(jQuery(window).width() >= 768){
	jQuery( ".block-guarantee li" ).hover(function(){
		jQuery( ".block-guarantee li" ).removeClass( "active" );
		jQuery( this ).addClass("active");
	});
    }

	jQuery('.side-nav-small .close').sideNav('hide');

    var showFooterMenu = jQuery(window).width() >= 993;
    jQuery(".footer-menu .list-services").toggle(showFooterMenu);

	jQuery( ".footer-menu .title-services-link" ).click(function(evt){
		//jQuery( ".footer-menu  .title-services" ).removeClass( "visible" );
        if (jQuery(window).width() < 993){
            jQuery(".list-services", jQuery(this).parent()).toggle(300);
            jQuery(jQuery(this).parent()).toggleClass("active");
        }
        evt.preventDefault();
	});

    jQuery(window).on("resize", function() {
        var visible = jQuery(window).width() >= 993;
        if (visible != showFooterMenu) {
            showFooterMenu = visible;
            jQuery(".footer-menu .list-services").toggle(visible);
            jQuery(".footer-menu .title-services").toggleClass("active", visible);
        }
    });

	jQuery('.carousel.carousel-slider-cards').carousel({
		dist:0,
		fullWidth:false,
		noWrap:false,
		indicators:true
	});


    if (jQuery.browser == 'msie' && jQuery.browser.version < 10) {
        jQuery('.col-text ul').multicolumn();
    }

    function resizeCards()
    {
        var cardHeight = 0;
        jQuery('.block-cards .div-card-text').height('');
        jQuery('.block-cards .div-card-text').each(function(key, el) {
            if (el.clientHeight > cardHeight)
                cardHeight = el.clientHeight;
        });
        jQuery('.block-cards .div-card-text').height(cardHeight);
    }

    jQuery('.slider_guarantis').on('init', function(e, slick) {
       resizeCards();
    });

    jQuery(window).on("load", resizeCards);
    jQuery(window).on("resize", resizeCards);

    $('#slick-block-guarantee').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
            breakpoint: 990,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

    /* Blog Posts */

    function resizeBlogCards()
    {
        var cardHeight = 0;
        jQuery('.page-blogs .post-blog .div-in').height('');
        if (jQuery(window).width() > 600) {
            jQuery('.page-blogs .post-blog .div-in').each(function(key, el) {
                if (el.clientHeight > cardHeight)
                    cardHeight = el.clientHeight;
            });
            jQuery('.page-blogs .post-blog .div-in').height(cardHeight);
        }
    }

    resizeBlogCards();

    jQuery(window).on("load",resizeBlogCards);
    jQuery(window).on("resize", resizeBlogCards);


    function resizeTestimCards()
    {
        var cardHeight = 0;
        jQuery('#testimonials-type-slider .testim-block .text-testimonials').height('');
        if (jQuery(window).width() > 600) {
            jQuery('#testimonials-type-slider .testim-block .text-testimonials').each(function(key, el) {
                if (el.clientHeight > cardHeight)
                    cardHeight = el.clientHeight;
            });
            jQuery('#testimonials-type-slider .testim-block .text-testimonials').height(cardHeight);
        }
    }

    resizeTestimCards();

    jQuery(window).on("load",resizeTestimCards);
    jQuery(window).on("resize",resizeTestimCards);

});

function get_fields(form)
{
 var element, values = {};
 for (var i = 0; i < form.elements.length; i++)
 {
  element = form.elements[i];
  if (element.name)
   switch (element.type)
   {
    case "checkbox": values[element.name] = element.checked ? element.value : ""; break;
    case "radio": if (!values[element.name]) values[element.name] = element.checked ? element.value : ""; break;
    default: values[element.name] = element.value;
   }
 }
 return values;
}

function verify_testim_form(form) {
    var fields = get_fields(form),
        required = ["type", "message"],
        ok = true, message = "Please fill out  all required fields";
    for (var i in required) if (!fields[required[i]]) {
        ok = false;
        jQuery(form.elements[required[i]]).addClass("error");
    }
    if (!ok) alert(message);
    return ok;
}

function submit_form(form, container, onsuccess, onerror)
{
    var $form = jQuery(form),
        url = $form.attr("action"),
        method = $form.attr("method");
    jQuery.ajax(url, {
        method: method.toUpperCase(),
        data: $form.serialize(),
        success: function(data, status, xhr) {
            jQuery(container).html(data);
            if (onsuccess)
                onsuccess(xhr);
        },
        error: function(xhr, status, error) {
            if (onerror)
                onerror(xhr);
        }
    });
    return false;
}

function valid_contact_hiring_form(form)
{
    var valid = true;
    if(form.elements.name.value.length < 1)
    {
        show_error_message(form.elements.name, 'name-error');
        valid = false;
    }
    if(!is_email(form.elements.email.value))
    {
        show_error_message(form.elements.email, 'email-error');
        valid = false;
    }
    if(form.elements.phone.value.length < 6)
    {
        show_error_message(form.elements.message, 'phone-error');
        valid = false;
    }
    if(form.elements.text.value.length < 4)
    {
        show_error_message(form.elements.message, 'text-error');
        valid = false;
    }
    if(form.elements.message.value.length < 4)
    {
        show_error_message(form.elements.message, 'message-error');
        valid = false;
    }
    return valid;
}
function show_error_message(element, hidden_class)
{
    add_class(element, 'error-required');
    show(document.getElementsByClassName(hidden_class)[0]);
}

function hide_error_message(element, hidden_class)
{
    remove_class(element, 'error-required');
    hide(document.getElementsByClassName(hidden_class)[0]);
}

function remove_class(element, class_name)
{
 if (element.className)
 {
  if (element.className == class_name)
   element.className = ""; else
  if ((new RegExp("(^|\\s)" + class_name + "(\\s|$)")).test(element.className))
  {
   var c = element.className.split(" ");
   for (var i = 0; i < c.length; i++)
    if (c[i] == class_name) c.splice(i, 1);
   element.className = c.join(" ");
  }
 }
}
document.addEventListener('DOMContentLoaded', function () {
    var lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy-background"));
    if ("IntersectionObserver" in window) {
    var lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
        if (entry.isIntersecting) {
            entry.target.classList.add("visible-bg");
            lazyBackgroundObserver.unobserve(entry.target);
        }
        });
    });
    lazyBackgrounds.forEach(function(lazyBackground) {
        lazyBackgroundObserver.observe(lazyBackground);
    });
    } else {
       var lazyBackground=document.getElementsByClassName('lazy-background');
       lazyBackground.classList.add('visible-bg');
    }
});
jQuery('.ae-sidebar-calc').on('onwsloaded', function() {
    if (window.General.isAuth()) {
        jQuery(".ae-sidebar-calc").removeClass("first_order");
    } else {
        jQuery(".ae-sidebar-calc").addClass("first_order");
    }
});
jQuery(".el-switch-style").on('click', function(e) {
    jQuery(".sidebar-total").toggleClass("hide-first-price");
});

jQuery('.ae-miniorder').on('onwsloaded', function() {
    if (window.General.isAuth()) {
        jQuery(".ae-miniorder").removeClass("first_order");
    } else {
        jQuery(".ae-miniorder").addClass("first_order");
    }
});
document.addEventListener('DOMContentLoaded', function () {
var footerRow = document.getElementById('bottom-row-wrapper');
if (footerRow) {
    footerRow.style.opacity = 1;
    var cookiename = "hide-bottom-banner";

    window.addEventListener('load', function () {
        setTimeout(function () {
            // if (document.cookie.indexOf(cookiename) === -1) {
            footerRow.style.display = 'block';
            footerRow.classList.remove('hidden-row');
            footerRow.classList.add('visible-row');
            // }
        }, 7000);
    });
}
    var cookieRow = document.getElementById('cookie-row-wrapper');
    if (cookieRow) {
        var cookieName = 'cookieBlockHidden';
        window.addEventListener('load', function () {
            setTimeout(function () {
                if (document.cookie.indexOf(cookieName) === -1) {
                    cookieRow.style.display = 'block';
                    cookieRow.classList.remove('hidden-cookie');
                    cookieRow.classList.add('visible-cookie');
                }
            }, 7000);
        });
    }
});
jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener('touchstart', handle, { passive: false });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener('touchmove', handle, { passive: false });
    }
};